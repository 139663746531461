import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useKeycloak } from "@react-keycloak/web";
import CollapsibleTableVariant from "../../curricular-plan/CollapsibleTableVariant";
import { columnsTitle } from "../../curricular-plan/AddCurricularPlan";
import { useParams } from "react-router-dom";
import FallBackLoader from "../../../components/FallBackComponents/FallBackLoader";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";

function revertSemesterToListData(semesterInfo, setListData, setStatus) {
  let listData = [];

  if (semesterInfo) {
    semesterInfo.forEach((info) => {
      const { semester, year, ...item } = info;

      // Convert year to string for consistency
      const yearKey = `${year}º Ano`;

      // Find the year object in the listData array
      let yearObject = listData.find((yearObj) => yearObj.year === yearKey);

      // If the year object doesn't exist, create it and add it to the listData array
      if (!yearObject) {
        yearObject = { year: yearKey };
        listData.push(yearObject);
      }

      const semesterKey = `${semester}º Semestre`;

      const newItem = {
        id: uuidv4(),
        name: item?.subject?.name || item?.subject, // Fallbacks For CourseDisciplines component
        subjectId: item?.subject?.id,
        procedente: item?.procedente
          ? item?.procedente?.name || item?.procedente
          : undefined, // Cannot be null because won't be droppable
        procedentId: item?.procedente ? item?.procedente?.id : undefined,
        creditos: Number(item.credits),
        dispenca: Boolean(Number(item.dispensation)),
        semester: `${yearKey}${semesterKey}`,
      };

      // Initialize semester if not already present
      if (!yearObject[semesterKey]) {
        yearObject[semesterKey] = [];
      }

      // Add new item to the semester
      yearObject[semesterKey].push(newItem);
    });
  }

  // Sort the listData array by year
  listData.sort((a, b) => a.year.localeCompare(b.year));
  setListData(listData);
  setStatus((prev) => ({ ...prev, isLoading: false }));
}

export default function CourseDisciplines({ schoolYearId }) {
  const [listData, setListData] = useState([]);
  let isDisabled = true;
  const { id } = useParams();
  const { classId, courseId } = useParams();
 
  const apiUrl = process.env.REACT_APP_API_URL;
  const { keycloak } = useKeycloak();
  //Table states
  const isEditing = !isDisabled;
  const [openSemester, setOpenSemester] = useState(null);
  const [selectedSemester, setSelectedSemester] = useState("");

  const [status, setStatus] = useState({
    isLoading: true,
    fetchingSubjects: true,
    noSubjects: false,
  });

  const handleActivateSemester = (semester) => {
    setSelectedSemester(semester);
  };

  const getCurricularPlanByCourseId = async () => {
    setStatus((prev) => ({ ...prev, isLoading: true }));

    try {
      const CurricularPlanResponse = await axios.get(
        `${apiUrl}/coordinator-course/${courseId}/curricular-plan/schoolYeaId/${schoolYearId}`,
        {
          headers: {
            Authorization: "Bearer " + keycloak.token,
          },
        }
      );

      console.log("DADOS: ", CurricularPlanResponse.data)
      revertSemesterToListData(
        CurricularPlanResponse?.data?.data,
        setListData,
        setStatus
      );

     
    } catch (error) {
      console.error("Erro ao obter dados do coordenador:", error);
    } finally {
      setStatus((prev) => ({ ...prev, isLoading: false }));
    }
  };



  useEffect(() => {
    if (keycloak.token && schoolYearId) {
      getCurricularPlanByCourseId();
    }
  }, [keycloak.token, schoolYearId]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <FallBackLoader
          isLoading={status.isLoading && (id ?? courseId) && !listData?.length}
        />
        {listData?.map((year, index) => (
          <Grid key={year.year}>
            <CollapsibleTableVariant
              title={year.year}
              secondTitles={Object.keys(year).filter((key) => key !== "year")}
              columnsTitle={columnsTitle}
              tableData={year}
              onIconClick={handleActivateSemester}
              selectedSemester={selectedSemester}
              handleDelete={() => {}}
              isEditing={isEditing}
              saveSemesterInputData={() => {}}
              setLastInputData={() => {}}
              year={year}
              yearIndex={index}
              openRow={openSemester}
              setOpenRow={setOpenSemester}
              firstColumnAlign="center"
              removeTruncateLength={true}
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}
CourseDisciplines.propTypes = {
  schoolYearId: PropTypes.string.isRequired,
};
