import React, { useEffect, useState } from "react";
import { Box, Typography, Button, IconButton, Select, MenuItem, FormControl, InputLabel, OutlinedInput, Divider, Breadcrumbs, Link, Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { MdOutlineKeyboardDoubleArrowLeft } from "react-icons/md";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate, useParams } from 'react-router-dom';

import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import axios from "axios";
import keycloak from "../../../keycloak";
import { i18n } from "../../../translate/i18n";
import GroupListingPreview from "../../class/component/GroupListingPreview";
import { Form, Formik } from "formik";

const apiUrl = process.env.REACT_APP_API_URL;


const SchedulePage = () => {
  const navigate = useNavigate();
  const { classId, courseId } = useParams()
  const [students, setStudents] = useState([])
  const [classData, setClassData] = useState(null);
  const [className, setClassName] = useState("");

  const navigateTo = () => {
    navigate(`groups/generate`)
  }

  const getClasse = async (id) => {
    try {
      const response = await axios.get(`${apiUrl}/classes/${id}`, {
        headers: {
          Authorization: "Bearer " + keycloak.token,
        },
      });
      console.log("DADOS DA TURMA: ", response.data, id)
      setClassData(response.data)
      const newStudents = response.data.students.map(student =>
        createData(student.name, student.group)
      );
      setStudents(newStudents);

      return response;
    } catch (error) {
      console.error(error);
    }
  };
  

  function createData(name, year, semester, subject, groups, classRoom) {
    return { name, year, semester, subject, groups, classRoom };
  }

  const rows = [
    createData(classData?._name, `${classData?.year}º Ano`, `${classData?.year}º Semestre`, classData?.subject.name, classData?.groups.length, `Sala do ${classData?.year}º Ano`),
  ];
  const [createdGroups, setCreatedGroups] = useState([]);

  const getGroups = async () => {
    try {
      const response = await axios.get(`${apiUrl}/group/${classId}/class`, {
        headers: {
          Authorization: "Bearer " + keycloak.token,
        },
      });
      return response;
    } catch (error) {
      console.error(error);
    }
  };

  const handleClassNameChange = (ev) => {
    setClassName(ev.target.value);
  };

  const handleGroupNameChange = (groupId, newName) => {
    setCreatedGroups((prevGroups) => {
      // Find the group with the matching id
      const groupIndex = prevGroups.groups.findIndex(
        (group) => group.id === groupId
      );

      // If the group exists, update its name
      if (groupIndex !== -1) {
        const newGroupNames = { ...prevGroups };
        newGroupNames.groups[groupIndex].name = newName;
        return newGroupNames;
      }

      // If the group doesn't exist, return the previous state
      return prevGroups;
    });
  };

  useEffect(() => {
    if (classId) {
      getClasse(classId)
    }
  }, [classId])

  useEffect(() => {
    if (classData?.groups.length > 0) {
      getGroups()
        .then((response) => {
          console.log("Group Data response: ", response);
          const groups =
            response?.data && response?.data?.data?.length
              ? response?.data?.data.sort((a, b) => a.id - b.id)
              : [];
          setCreatedGroups((prev) => ({
            ...prev,
            groups,
          }));
          setClassName(classData?.name)
          /* setStatus((prev) => ({
            ...prev,
            hasGroups: groups?.length > 0,
          })); */
        })
        .catch((error) => {
          console.log("ERRO AO PEGAR O GRUPO: ", error);
        });
    }
  }, [classData?.groups.length]);

  return (
    <Box
    /* sx={{
      backgroundColor: "#FFFFFF",
    }} */
    >
      <Box
        sx={{
          backgroundColor: "#FFFFFF",
        }}
        p={2}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 5 }}>
          <Button sx={{ borderColor: "transparent" }} startIcon={<MdOutlineKeyboardDoubleArrowLeft size={32} />}>
            {i18n.t("others.back")}
          </Button>
          <Breadcrumbs aria-label="breadcrumb" sx={{ borderBottom: "none", color: "#8893A9" }}>
            <Link underline="hover" color="inherit" href="/classes">
              {i18n.t("course.class")}
            </Link>
            <Link
              underline="hover"
              color="inherit"
              href={`/classes/${classId}/${courseId}/preview`}
            >
              {classData?._name}
            </Link>
            <Link
              underline="hover"
              color="secondary"
              href="#"
              aria-current="gerar grupo"
            >
              {i18n.t("others.generateGroups")}
            </Link>
          </Breadcrumbs>
        </Box>
      </Box>

      <Box
        sx={{
          backgroundColor: "#FFFFFF",
        }}
        p={2}
        mt={4}
      >
        <Typography variant="h3" fontSize={24}>Descrição da Turma</Typography>
      </Box>
      <TableContainer component={Paper} sx={{ borderRadius: "0" }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead sx={{ bgcolor: "#E9ECF5" }}>
            <TableRow>
              <TableCell align="left" sx={{ color: "#8893A9" }}>Graduação</TableCell>
              <TableCell align="left" sx={{ color: "#8893A9" }}>Ano</TableCell>
              <TableCell align="left" sx={{ color: "#8893A9" }}>Semestre</TableCell>
              <TableCell align="left" sx={{ color: "#8893A9" }}>Disciplina</TableCell>
              <TableCell align="left" sx={{ color: "#8893A9" }}>Grupos</TableCell>
              <TableCell align="left" sx={{ color: "#8893A9" }}>Sala</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.name}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell align="left">{row.year}</TableCell>
                <TableCell align="left">{row.semester}</TableCell>
                <TableCell align="left">{row.subject}</TableCell>
                <TableCell align="left">{row.groups}</TableCell>
                <TableCell align="left">{row.classRoom}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>


      {
        classData?.groups.length > 0 ?
          <Formik>
            <Form>
              <GroupListingPreview
                turma={createdGroups}
                handleClassNameChange={handleClassNameChange}
                className={className}
                handleGroupNameChange={handleGroupNameChange}
                onClick={navigateTo}
              />
            </Form>
          </Formik>
          :
          <>
            <Box
              sx={{
                backgroundColor: "#FFFFFF",
              }}
              p={2}
              mt={4}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Typography variant="h3" fontSize={24}>{i18n.t("student.list")}</Typography>
              <Button onClick={navigateTo} variant="contained" sx={{ bgcolor: "#FFC107", color: "white" }}>{i18n.t("others.generateGroups")}</Button>
            </Box>
            <TableContainer component={Paper} sx={{ borderRadius: "0" }}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead sx={{ bgcolor: "#E9ECF5" }}>
                  <TableRow>
                    <TableCell colSpan={2} align="left" sx={{ color: "#8893A9" }}>{i18n.t("others.name")}</TableCell>
                    <TableCell colSpan={4} align="left" sx={{ color: "#8893A9" }}>{i18n.t("others.group")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {students.map((student) => {
                    console.log("ESTUDANTE: ", student, students)
                    return (
                      <TableRow
                        key={student.id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell colSpan={2} align="left">{student.name}</TableCell>
                        <TableCell colSpan={4} align="left">{student.year ?? "N/G"}</TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </>
      }

    </Box>
  );
};

export default SchedulePage;
