import { Box, Grid, Tab, Tabs, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Form, Formik } from 'formik'
import axios from 'axios'

import Textfield from "../../components/form-components/text-field"
import { solveDate, TabPanel } from '../course/create/CourseRegisterPage'
import { i18n } from '../../translate/i18n'
import keycloak from '../../keycloak'
import CourseDisciplines from '../course/create/CourseDisciplines'
export const DetailClass = () => {
    const [courseData, setCourseData] = useState()
    const [schoolYearId, setSchoolYearId] = useState();
    const [value, setValue] = useState(0);
    const apiUrl = process.env.REACT_APP_API_URL;
    const { courseId } = useParams();
    const loggedUsername = keycloak.tokenParsed.preferred_username;

    const getCoordinatorByUserId = async () => {
        try {
            const courseResponse = await axios.get(`${apiUrl}/courses/${courseId}`, {
                headers: {
                    Authorization: "Bearer " + keycloak.token,
                },
            });
            const courseData = courseResponse?.data;
            setCourseData(courseData);
            setSchoolYearId(courseData?.courseCoordination?.schoolYear?.id);
        } catch (error) {
            console.error("Erro ao obter dados do coordenador:", error);
        }
    };

    useEffect(() => {
        if (keycloak.token) {
            getCoordinatorByUserId();
        }
    }, [keycloak, courseId]);

    console.log("ID: ", schoolYearId)

    return (
        <Box>
            <Box
                backgroundColor="white"
                sx={{
                    padding: "55px 110px 50px",
                    margin: "0 -100px 20px -50px",
                }}
            >
                <Formik>
                    <Form>
                        <Typography variant="h1" gutterBottom paddingBottom={"30px"}>
                            {i18n.t("course.titleCreate")}
                        </Typography>
                        <Grid
                            container
                            spacing={3}
                        >
                            <Grid item xs={5}>
                                <Textfield
                                    name="name"
                                    value={courseData?.name}
                                    disabled
                                    label={i18n.t("others.name")}
                                />
                            </Grid>
                            <Grid item xs={2.4}>
                                <Textfield
                                    name="lativeYear"
                                    value={solveDate(courseData)}
                                    disabled
                                    label={i18n.t("others.lativeYear")}
                                />
                            </Grid>
                            <Grid item xs={5}>
                                <Textfield
                                    name="graduation"
                                    value={courseData?.graduation}
                                    disabled
                                    label={i18n.t("others.graduation")}
                                />
                            </Grid>
                            <Grid item xs={5}>
                                <Textfield
                                    name="department"
                                    value={courseData?.department}
                                    disabled
                                    label={i18n.t("others.department")}
                                />
                            </Grid>
                            <Grid item xs={5}>
                                <Textfield
                                    name="coordenator"
                                    value={loggedUsername}
                                    disabled
                                    label={i18n.t("others.coordinator")}
                                />
                            </Grid>
                            <Grid item xs={2.4}>
                                <Textfield
                                    name="Workload"
                                    disabled
                                    value={courseData?.workingHours}
                                    label={i18n.t("others.workload")}
                                />
                            </Grid>
                            <Grid item xs={2.4}>
                                <Textfield
                                    name="numberOfStudents"
                                    disabled
                                    label={i18n.t("others.numberOfStudents")}
                                    type="number"
                                    value={courseData?.totalStudents}
                                />
                            </Grid>
                        </Grid>
                    </Form>
                </Formik>
            </Box>
            <Grid
                style={{
                    backgroundColor: "white",
                    padding: "15px",
                    marginLeft: "-50px",
                    marginRight: "-100px",
                }}
            >
                <Box>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            backgroundColor: "#F1F1E1",
                            height: "75px",
                            marginX: "15px",
                        }}
                    >
                        <Tabs
                            value={value}
                            onChange={(event, newValue) => setValue(newValue)}
                            aria-label="basic tabs example"
                            sx={{
                                height: "10px",
                                display: "flex",
                                alignItems: "center",
                                margin: "auto",
                                marginLeft: "20px",
                            }}
                        >
                            <Tab
                                label={i18n.t("others.disciplines")}
                                style={{
                                    backgroundColor: value === 0 ? "#FFC107" : "",
                                    color: value === 0 ? "#f3f3f3" : "#2B434E",
                                }}
                            />
                        </Tabs>
                    </Box>

                    <TabPanel value={value} index={0}>
                        <CourseDisciplines
                            schoolYearId={schoolYearId?.toString()}
                            totalNumberOfYears={courseData?.totalNumberOfYears}
                        />
                    </TabPanel>
                </Box>
            </Grid>
        </Box>
    )
}
