import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate, useParams } from "react-router-dom";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import axios from "axios";
const SchedulePage = () => {
  const navigate = useNavigate();
  const [strands, setStrands] = useState([]);
  const [periods, setPeriods] = useState([]);
  const [selectedStrand, setSelectedStrand] = useState("");
  const [selectedPeriod, setSelectedPeriod] = useState("");
  const [turmas, setTurmas] = useState([]);
  const [selectedTurma, setSelectedTurma] = useState(null);
  const { cursoId, ano, semestre} = useParams();
  const [courses, setCourses] = useState([]);
  const [schedulerPData, setSchedulerPData] = useState([]);
  const [schedules, setSchedules] = useState([]);
  const [loading, setLoading] = useState(true);
  const [days, setDays] = useState([]); 
  const [isDisabled, setIsDisabled] = useState(true); 

  const [hours, setHours] = useState([]); 
  const apiUrl = `${process.env.REACT_APP_API_URL}/classes`;

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await axios.get(apiUrl);
        const courseData = response.data.data;

        // Combinar _name e course.name
        const filteredData = courseData.map((item) => ({
          combinedName: `${item.course.name}
          ${item._name} 
           `,
        }));

        setCourses(filteredData);
      } catch (error) {
        console.error('Erro ao buscar cursos:', error);
      }
    };

    fetchCourses();
  }, [apiUrl]);
  // Função para atualizar a URL e buscar as turmas
  const fetchTurmas = async () => {
    try {
      // Construção da URL dinâmica
      const apiUrl = `${process.env.REACT_APP_API_URL.replace(/\/$/, '')}/classes/${cursoId}/${ano}/${semestre}`;
      
      const response = await axios.get(apiUrl);
      const dados = response.data;
      
      // Verificar se a resposta contém um array de turmas
      const formattedTurmas = dados.map(turma => ({
        id: turma.id,
        name: turma._name
      }));
      
      console.log("Formatted Turmas:", formattedTurmas);
  
      setTurmas(formattedTurmas); // Atualizar o estado com o array de turmas
  
    } catch (error) {
      console.error('Erro ao buscar turmas:', error);
    }
  };
  

  const fetchDays = async () => {
    try {
      const response = await axios.get("/teaching-days");
      setDays(response.data); // Atualiza os dias no estado
    } catch (error) {
      console.error("Erro ao buscar os dias da semana:", error);
    }
  };
  useEffect(() => {
    fetchDays();
  }, []);

  const fetchHours = async () => {
    try {
      const response = await axios.get(
        "/teaching-time/period/2b1755f9-9005-4d84-aa2f-c31ed6faad43"
      );
      setHours(response.data); // Atualiza o estado com os horários
    } catch (error) {
      console.error("Erro ao buscar horários:", error);
    }
  };

  useEffect(() => {
    fetchHours(); // Chamada para buscar os horários ao carregar o componente
  }, []);
  // UseEffect para chamar a API sempre que cursoId, ano ou semestre mudarem
  useEffect(() => {
    fetchTurmas();
  }, [cursoId, ano, semestre]); // Dependências que causam a execução quando algum desses valores mudar

  
    useEffect(() => {
      // Função para buscar vertentes
      const fetchStrands = async () => {
        try {
          const response = await axios.get("/strand");
          setStrands(response.data);
        } catch (error) {
          console.error("Erro ao buscar vertentes:", error);
        }
      };
      // Função para buscar períodos
      const fetchPeriods = async () => {
        try {
          const response = await axios.get("/period");
          setPeriods(response.data);
        } catch (error) {
          console.error("Erro ao buscar períodos:", error);
        }
      };
  
      fetchStrands();
      fetchPeriods();
    }, []);
  useEffect(() => {
    axios.get('/scheduler')
      .then(response => setSchedules(response.data.data))
      .catch(error => console.error("Erro ao buscar os dados do agendador:", error));

    fetchSchedulerP();
  }, []);

  const fetchSchedulerP = async () => {
    try {
      const response = await axios.get("/scheduler-p");
      setSchedulerPData(response.data.data || []);
      setLoading(false);
    } catch (error) {
      console.error("Erro ao buscar dados do agendador-p:", error);
      setLoading(false);
    }
  };

  if (loading) {
    return <div>Carregando...</div>;
  }
  const handleChange = (event) => {
    setSelectedTurma(event.target.value);
  };
   
   
  const combinedData = [
    ...schedules.flatMap(schedule => 
      schedule.schedulerDetails.map(detail => ({
        id: detail.id,
        discipline: detail.subject?.name || "N/A",
        strand: "T", 
        room: detail.subject?.classroom?.name || "N/A",
      }))
    ),
    ...schedulerPData.map(data => ({
      id: data.id,
      discipline: data.subject?.name || "N/A",
      strand: "P", 
      room: data.subject?.classroom?.name || "N/A",
    }))
  ];
console.log("Combined data:", combinedData);

  return (
    <Box sx={{
      backgroundColor: "",
      height: "100vh",
      padding: "-300px",
      width: "1220px !important" , 

      maxWidth: "1200000px", 
      margin: "0 auto" 
    }}>      
      {/* Cabeçalho */}
      <Box sx={{ 
  backgroundColor: "#FFFFFF", 
  marginRight:"10px",
  boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)", 
 borderRadius: "",
   padding: "20px", 
   marginTop: "-30px",  
     width: "1213px !important" , 
     marginLeft: "-40px", 
     height:"10px",
}}> 
  <Box sx={{ display: "flex", alignItems: "center", marginBottom: "20px", }}>
    {/* Ícone de voltar com duas setas */}
    <IconButton sx={{ 
      color: "#FBC02D", 
      marginRight: "0px", 
      display: "flex", 
      alignItems: "center", 
    }}
    IconButton onClick={() => navigate('')} disabled={isDisabled}>
    
      {/* Primeira seta com tamanho reduzido */}
      <ArrowBackIosNewIcon sx={{ fontSize: "18px", marginLeft: "-5px " ,      marginTop: "-8px", }} />
      {/* Segunda seta com tamanho reduzido */}
      <ArrowBackIosNewIcon sx={{ fontSize: "18px", marginLeft: "-10px " ,      marginTop: "-8px", }} />
    </IconButton>
    {/* Texto "Voltar" em amarelo */}
    <Typography variant="h6" sx={{ 
      fontWeight: 400, 
      fontSize: "18px", 
      color: "#FBC02D", 
      marginRight: "10px", 
      marginTop: "-8px",
    }}>
      Voltar
    </Typography>
    {/* Texto "Horário" em cinza */}
    <Typography variant="h6" sx={{ 
      fontWeight:4600, 
      fontSize: "18px", 
      color: "#8893A9" ,
      marginTop: "-8px",
      gap:"20px",

    }}>
      Horário
    </Typography>
  </Box>
</Box>
      <Box sx={{ backgroundColor: "#FFFFFF", borderRadius: "", padding: "20px", marginTop: "15px",    width: "1220px !important" , marginLeft: "-40px", height:"630px",}}>
  <Typography variant="h6" sx={{ fontWeight: 600, fontSize: "18px", color: "#333333" }}>
    Horário
  </Typography>

  {/* Filtros */}
  <Box sx={{
      border: "1px solid #D6DEE6", 
  width: "100%",
  maxWidth: "107000px", 
  height: "60px",
  margin: "0 auto",
  position: "relative", 
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "2px",
  gap: "10px"
}}>
<Box
  sx={{
    
    backgroundColor: "#FFFFFF",
    padding: "0px",
    width: "100%",
    margin: "0 auto",
    }}>
     <Box
  sx={{
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "10px",
    marginTop: "2px", 
  }}
>
  {/* Seleção de Turma */}
  <FormControl sx={{ minWidth: "140px", backgroundColor: "white", borderRadius: "6px" }}disabled={isDisabled}>
  <InputLabel sx={{ fontSize: "14px", color: "#8893A9" }}>Turma</InputLabel>
  <Select
    value={selectedTurma}
    onChange={handleChange}
    input={<OutlinedInput />}
    sx={{ borderRadius: "6px", height: "40px" }}
  >
    {turmas && turmas.map((turma) => (  // Usando .map para iterar sobre o array de turmas
      <MenuItem key={turma.id} value={turma.id}>
        {turma.name}  {/* Nome da turma */}
      </MenuItem>
    ))}
  </Select>
</FormControl>
              {/* Seleção de Vertente */}
              <FormControl sx={{ minWidth: "140px", backgroundColor: "white", borderRadius: "6px" }}disabled={isDisabled}>
                <InputLabel sx={{ fontSize: "14px", color: "#8893A9" }}>Vertente</InputLabel>
                <Select value={selectedStrand} onChange={(e) => setSelectedStrand(e.target.value)} input={<OutlinedInput />} sx={{ borderRadius: "6px", height: "40px" }}>
                  {strands.map((strand) => (
                    <MenuItem key={strand.id} value={strand.id}>
                      {strand.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {/* Seleção de Período */}
              <FormControl sx={{ minWidth: "140px", backgroundColor: "white", borderRadius: "6px" }}disabled={isDisabled}>
                <InputLabel sx={{ fontSize: "14px", color: "#8893A9" }}>Período</InputLabel>
                <Select value={selectedPeriod} onChange={(e) => setSelectedPeriod(e.target.value)} input={<OutlinedInput />} sx={{ borderRadius: "6px", height: "40px" }}>
                  {periods.map((period) => (
                    <MenuItem key={period.id} value={period.id}>
                      {period.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
        {/* Botão de pesquisa */}
        <IconButton sx={{
          backgroundColor: "#D6DEE6",
          color: "white",
          borderRadius: "6px",
          width: "20px",
          height: "18px",
          padding: "12px 13px",
          marginLeft: 0
        }}>
          <SearchIcon />
        </IconButton>
      </Box>
{/* Botão Criar Horário */}
<Box sx={{ display: "flex", color: "#FFF", justifyContent: "flex-end", width: "100%" }}>
{/* <Button
      variant="contained"
      sx={{
        backgroundColor: "#FBC02D",
        color: "white",
        fontWeight: 400,
        height: "33px",
        paddingRight: "-300px",
        marginRight: "10px",
        textTransform: "none",
        borderRadius: "6px",
        marginTop: "-40px",
        ":hover": { backgroundColor: isDisabled ? "#FBC02D" : "#FFA000" }, 
      }}
      disabled={isDisabled} 
      onClick={() => ('')}
    >
      Visualizar Horário
    </Button> */}
</Box>
    </Box>
  </Box>
  {/* Grade de horários */}
  <Box sx={{ marginTop: "30px" }}>
  {/* Cabeçalho da tabela */}
  <Box sx={{
    display: "grid",
    width: "100%", // Largura uniforme
    gridTemplateColumns: `80px repeat(${days.length}, 1fr)`, 
    textAlign: "center",
    backgroundColor: "#FFFFFF",
    fontWeight: 600,
    fontSize: "14px",
    marginBottom: "20px",
    color: "#212529"
  }}>
    <Typography></Typography>
    {days.map((day, index) => (
      <Typography key={index}>{day.name}</Typography>
    ))}
  </Box>
    {/* Linha de horários */}
    <Box sx={{
      display: "grid",
      
      gridTemplateColumns: "80px repeat(7, 1fr)",
      gap: "10px",
      alignItems: "center",
    }}>
      {/* Horários na lateral */}
      <Box>
        {["07:30", "08:30", "09:30", "10:30", "11:30", "12:30"].map((time, index) => (
          <Typography key={index} sx={{
            textAlign: "center", fontSize: "14px", backgroundColor: "#FFFFFF", padding: "10px 0" , color:"#212529",             marginBottom: "45px" 
          }}>
            {time}
          </Typography>
        ))}
      </Box>  
      <div
  style={{
    display: "grid",
    gridTemplateColumns: "repeat(6, 183px)",
    marginTop: "-10px",
    gap: "0px",
    borderCollapse: "collapse",
    width: "1100px",
  }}
>
  {combinedData.map((item, index) => (
    <div
      key={item.id || index}
      style={{
        border: "1px solid #D6DEE6",
        padding: "15px",
        height: "40px",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography variant="body1" sx={{ margin: "0", fontSize: "13px" }}>
        {item.discipline} {item.strand} {/* Primeiro Disciplina */}
      </Typography>

      <Typography variant="body2" sx={{ margin: "0", fontSize: "13px" }}>
        {item.room} {/* Outros dados */}
      </Typography>
    </div>
  ))}

  {/* Preencher células vazias para garantir 6 colunas por linha */}
  {combinedData.length % 6 !== 0 &&
    [...Array(6 - (combinedData.length % 6))].map((_, emptyIndex) => (
      <div
        key={`empty-${emptyIndex}`}
        style={{
          border: "1px solid #D6DEE6",
          padding: "15px",
          height: "50px",
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {"\u00A0"}
      </div>
    ))}
</div>


      </Box>
    </Box>
  </Box>
</Box>
  );
};
export default SchedulePage;
