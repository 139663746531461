import { Grid, MenuItem, TextField } from "@mui/material";
import { useField, useFormikContext } from "formik";
import PropTypes from 'prop-types';
const SelectWrapper = ({
  name,
  options,
  setOption,
  defaultValue,
  onChange,
  disabled,
  label,
  sendName = false, // Adicionamos a opção sendName, padrão para false
  ...otherProps
}) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(name);

  const handleChange = (e) => {
    if (onChange) onChange(e);
    const { value } = e.target;

    // Find the selected option to display its name
    const selectedOption = options?.find((option) => option.id === value);

    // If sendName is true, set the field value to the name of the selected option
    if (sendName && typeof selectedOption === "object") {
      setFieldValue(name, selectedOption.name);
    } else {
      // Otherwise, set the field value to the id of the selected option
      setFieldValue(name, value);
    }

    if (setOption) setOption(value);
  };

  const selectConfig = {
    ...otherProps,
    ...field,
    size: "small",
    select: true,
    variant: "outlined",
    fullWidth: true,
    label: false,
    onChange: handleChange,
    defaultValue,
    style: { backgroundColor: "white" },
    error: meta.touched && meta.error,
    helperText: meta.touched ? meta.error : "",
  };

  console.log("SELECT DEFAULT VALUE: ", defaultValue, options);

  return (
    <Grid item container xs={12}>
      <Grid item xs={12}>
        <label>
          <p
            style={{
              fontSize: "13px",
              fontWeight: "600",
              color: "#42474B",
              margin: "0px",
            }}
          >
            {label}
          </p>
        </label>
      </Grid>

      <Grid item container xs={12}>
        <Grid
          item
          xs={otherProps.setListinOptions ? 11 : 12}
          sx={disabled ? { pointerEvents: "none" } : {}}
        >
          <TextField
            disabled={disabled}
            {...selectConfig}
            sx={{
              "& .MuiInputBase-root": {
                borderStyle: "solid",
                borderColor: "#E9ECF5",
                "&.Mui-focused": {
                  outline: "none",
                  borderColor: "#C3CAD9",
                },
              },
              ...selectConfig?.sx, //To override the default styles
            }}
          >
            {Array.isArray(options)
              ? options.map((option) => {
                let displayText;
                if (option.firstName && option.lastName) {
                  displayText = `${option.firstName} ${option.lastName}`;
                } else if (option.subject) {
                  displayText = `${option.subject.name}`;
                } else if (option.name || option._name) {
                  displayText = option.name || option._name;
                } else if (option.value || option._value) {
                  displayText = option.value || option._value;
                } else {
                  displayText = option;
                }
                return (
                  <MenuItem
                    key={option.id || `${option.name}-${option.id}`} // Assuming id is unique
                    value={option.subject ? option.subject.id : option.id || option}
                    sx={{
                      "&:hover": {
                        backgroundColor: "warning.main", // replace with your theme's yellow color
                        color: "#fff",
                      },
                    }}
                  >
                    {displayText}
                  </MenuItem>
                );
              })
              : Object.keys(options).map((item) => (
                <MenuItem
                  key={options[item].id || `${options[item].name}-${options[item].id}`} // Assuming id is unique
                  value={options[item].id || options[item]}
                  sx={{
                    "&:hover": {
                      backgroundColor: "warning.main", // replace with your theme's yellow color
                      color: "#fff",
                    },
                  }}
                >
                  {options[item].name || options[item]}
                </MenuItem>
              ))}

          </TextField>
        </Grid>
      </Grid>
    </Grid>
  );
};

SelectWrapper.propTypes = {
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      })
    ),
    PropTypes.object, // assuming options can also be an object
  ]),
}; SelectWrapper.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      })
    ),
    PropTypes.object,
  ]).isRequired,
  setOption: PropTypes.func,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  sendName: PropTypes.bool,
};

export default SelectWrapper;
