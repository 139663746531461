import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  OutlinedInput,
  Snackbar,
  Alert,
} from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate, useParams } from "react-router-dom";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import axios from "axios";
import { id } from "date-fns/locale";
import CloseIcon from "@mui/icons-material/Close";

import { i18n } from "../../translate/i18n";
const SchedulePage = () => {
  const navigate = useNavigate();
  const [strands, setStrands] = useState([]);
  const [periods, setPeriods] = useState([]);
  const [selectedStrand, setSelectedStrand] = useState("");
  const [selectedPeriod, setSelectedPeriod] = useState("");
  const [turmas, setTurmas] = useState([]);
  const [selectedTurma, setSelectedTurma] = useState(null);
  const { cursoId, year, semester} = useParams();
  const [days, setDays] = useState([]);
  const [courseDetails, setCourseDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [schedulerPData, setSchedulerPData] = useState([]);
  const [schedules, setSchedules] = useState([]);
  const [hours, setHours] = useState([]);
  const [showMessage, setShowMessage] = useState(true);

  const handleClose = () => {
    setShowMessage(false); // Fecha a mensagem quando o "X" é clicado
  };
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState(""); 
  
  const extrairNumero = (texto) => 
    {
    if (texto === undefined || texto === null) {
      return null; // Retorna null se o valor for indefinido ou nulo
    }
    const match = String(texto).match(/\d+/); // Converte para string antes de aplicar .match
    return match ? parseInt(match[0], 10) : null; // Retorna o número ou null se não encontrar
  };

  useEffect(() => {
    const fetchCourseDetails = async () => {
      try {
        const response = await axios.get(`/courses/${cursoId}`);
        setCourseDetails(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Erro ao buscar detalhes do curso:', error);
        setLoading(false);
      }
    };

    fetchCourseDetails();
  }, [cursoId]);

  const fetchTurmas = async () => {
    try {
      const apiUrl = `${process.env.REACT_APP_API_URL.replace(/\/$/, '')}/classes/${cursoId}/${year}/${semester}`;
      
      const response = await axios.get(apiUrl);
      const dados = response.data;
      
      const formattedTurmas = dados.map(turma => ({
        id: turma.id,
        name: turma._name
      }));
      
      console.log("Formatted Turmas:", formattedTurmas);
  
      setTurmas(formattedTurmas);
  
    } catch (error) {
      console.error('Erro ao buscar turmas:', error);
    }
  };
  

  const fetchDays = async () => {
    try {
      const response = await axios.get("/teaching-days");
      setDays(response.data); // Atualiza os dias no estado
    } catch (error) {
      console.error("Erro ao buscar os dias da semana:", error);
    }
  };
  useEffect(() => {
    fetchDays();
  }, []);

  const fetchHours = async () => {
    try {
      const response = await axios.get(
        "/teaching-time/period/2b1755f9-9005-4d84-aa2f-c31ed6faad43"
      );
      setHours(response.data); // Atualiza o estado com os horários
    } catch (error) {
      console.error("Erro ao buscar horários:", error);
    }
  };

  useEffect(() => {
    fetchHours(); // Chamada para buscar os horários ao carregar o componente
  }, []);
  useEffect(() => {
    fetchTurmas();
  }, [cursoId, year, semester]); 
  const handleChange = (event) => {
    setSelectedTurma(event.target.value); // Atualiza a turma selecionada
  };
    useEffect(() => {
      // Função para buscar vertentes
      const fetchStrands = async () => {
        try {
          const response = await axios.get("/strand");
          setStrands(response.data);
        } catch (error) {
          console.error("Erro ao buscar vertentes:", error);
        }
      };
      // Função para buscar períodos
      const fetchPeriods = async () => {
        try {
          const response = await axios.get("/period");
          setPeriods(response.data);
        } catch (error) {
          console.error("Erro ao buscar períodos:", error);
        }
      };
  
      fetchStrands();
      fetchPeriods();
      
    }, []);

    useEffect(() => {
      // Primeira chamada para o endpoint atualizado
      axios
        .get("/scheduler")
        .then((response) => setSchedules(response.data.data))
        .catch((error) => console.error("Erro ao buscar os dados do agendador:", error));
    
      fetchSchedulerP();
    }, []);
    
    const fetchSchedulerP = async () => {
      try {
        // Segunda chamada para o endpoint atualizado
        const response = await axios.get(
          "/scheduler-p"
        );
        setSchedulerPData(response.data.data || []);
        setLoading(false);
      } catch (error) {
        console.error("Erro ao buscar dados do agendador-p:", error);
        setLoading(false);
      }
    };
  if (loading) {
    return <div>Carregando...</div>;
  }
 
   
   
  const combinedData = [
    ...schedules.flatMap(schedule => 
      schedule.schedulerDetails.map(detail => ({
        id: detail.id,
        discipline: detail.subject?.name || "N/A",
        strand: "T", 
        room: detail.subject?.classroom?.name || "N/A",
      }))
    ),
    ...schedulerPData.map(data => ({
      id: data.id,
      discipline: data.subject?.name || "N/A",
      strand: "P", 
      room: data.subject?.classroom?.name || "N/A",
    }))
  ];
  
console.log("Combined data:", combinedData);
const handleUpdateSchedule = async () => {
  if (!selectedTurma || !selectedStrand || !selectedPeriod) {
    setErrorMessage("Por favor, preencha todos os campos.");
    return;
  }

  const payload = {
    turmaId: selectedTurma,
    vertenteId: selectedStrand,
    periodoId: selectedPeriod,
  };

  try {
    await axios.put(`/schedule/${cursoId}`, payload);
    setSuccessMessage("Horário atualizado com sucesso!");
  } catch (error) {
    console.error("Erro ao atualizar o horário:", error);
    setErrorMessage("Erro ao atualizar o horário. Tente novamente.");
  }
};

  return (
    <Box sx={{
      backgroundColor: "",
      height: "100vh",
      padding: "-300px",
      width: "1220px !important" , 

      maxWidth: "1200000px", 
      margin: "0 auto" 
    }}>
      
      {/* Cabeçalho */}
      <Box sx={{ 
  backgroundColor: "#FFFFFF", 
  marginRight:"10px",
  boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)", 
 borderRadius: "",
   padding: "20px", 
   marginTop: "-30px",  
     width: "1213px !important" , 
     marginLeft: "-40px", 
     height:"10px",
}}> 

  <Box sx={{ display: "flex", alignItems: "center", marginBottom: "20px", }}>
    {/* Ícone de voltar com duas setas */}
    <IconButton sx={{ 
      color: "#FBC02D", 
      marginRight: "0px", 
      display: "flex", 
      alignItems: "center", 
    }}
    onClick={() => navigate('/schedules')}
    >
      {/* Primeira seta com tamanho reduzido */}
      <ArrowBackIosNewIcon sx={{ fontSize: "18px", marginLeft: "-5px " ,      marginTop: "-8px", }} />
      <ArrowBackIosNewIcon sx={{ fontSize: "18px", marginLeft: "-10px " ,      marginTop: "-8px", }} />
    </IconButton>
    <Typography variant="h6" sx={{ 
      fontWeight: 400, 
      fontSize: "18px", 
      color: "#FBC02D", 
      marginRight: "10px", 
      marginTop: "-8px",
    }}>
      Voltar
    </Typography>
    {/* Texto "Horário" em cinza */}
    <Typography variant="h6" sx={{ 
      fontWeight:4600, 
      fontSize: "18px", 
      color: "#8893A9" ,
      marginTop: "-8px",
      gap:"20px",

    }}>
      Horário
    </Typography>
  </Box>
</Box>
      <Box sx={{ backgroundColor: "#FFFFFF", borderRadius: "", padding: "20px", marginTop: "15px",    width: "1220px !important" , marginLeft: "-40px", height:"630px",}}>
  <Typography variant="h6" sx={{ fontWeight: 600, fontSize: "18px", color: "#333333" }}>
    Horário
  </Typography>

  {/* Filtros */}
  <Box sx={{
      border: "1px solid #D6DEE6",
  width: "100%", 
  maxWidth: "107000px", 
  height: "60px", 
  margin: "0 auto", 
  position: "relative", 
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "2px", 
  gap: "10px"
}}>

<Box
  sx={{
    
    backgroundColor: "#FFFFFF",
    padding: "0px",
    width: "100%", 
    margin: "0 auto", 
    }}>
     <Box
  sx={{
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "10px",
    marginTop: "2px",  
  }}
>
  {/* Seleção de Turma */}
  <FormControl sx={{ minWidth: "140px", backgroundColor: "white", borderRadius: "6px" }}>
  <InputLabel sx={{ fontSize: "14px", color: "#8893A9" }}>Turma</InputLabel>
  <Select
    value={selectedTurma}
    onChange={(e) => setSelectedTurma(e.target.value)}
    input={<OutlinedInput />}
    sx={{ borderRadius: "6px", height: "40px" }}
  >
    {turmas && turmas.map((turma) => (  // Usando .map para iterar sobre o array de turmas
      <MenuItem key={turma.id} value={turma.id}>
        {turma.name}  {/* Nome da turma */}
      </MenuItem>
    ))}
  </Select>
</FormControl>


              {/* Seleção de Vertente */}
              <FormControl sx={{ minWidth: "140px", backgroundColor: "white", borderRadius: "6px" }}>
                <InputLabel sx={{ fontSize: "14px", color: "#8893A9" }}>Vertente</InputLabel>
                <Select value={selectedStrand} onChange={(e) => setSelectedStrand(e.target.value)} input={<OutlinedInput />} sx={{ borderRadius: "6px", height: "40px" }}>
                  {strands.map((strand) => (
                    <MenuItem key={strand.id} value={strand.id}>
                      {strand.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {/* Seleção de Período */}
              <FormControl sx={{ minWidth: "140px", backgroundColor: "white", borderRadius: "6px" }}>
                <InputLabel sx={{ fontSize: "14px", color: "#8893A9" }}>Período</InputLabel>
                <Select value={selectedPeriod}             onChange={(e) => setSelectedPeriod(e.target.value)}
 input={<OutlinedInput />} sx={{ borderRadius: "6px", height: "40px" }}>
                  {periods.map((period) => (
                    <MenuItem key={period.id} value={period.id}>
                      {period.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

        {/* Botão de pesquisa */}
        <IconButton sx={{
          backgroundColor: "#D6DEE6",
          color: "white",
          borderRadius: "6px",
          width: "20px",
          height: "18px",
          padding: "12px 13px",
          marginLeft: 0
        }}>
          <SearchIcon />
        </IconButton>
      </Box>

{/* Botão Criar Horário */}
 <Box sx={{ display: "flex", color: "#FFF", justifyContent: "flex-end", width: "100%" }}>
  <Button
    variant="contained"
    sx={{
      backgroundColor: "#FBC02D",
      color: "white",
      fontWeight: 400,
      height: "33px",
      paddingRight: "-300px", 
      marginRight: "10px", 
      textTransform: "none" , 

      borderRadius: "6px",
      marginTop: "-40px", 
      ":hover": { backgroundColor: "#FBC02D" },
    }}
    onClick={() => navigate('')}
  >
    Actualizar Horário
  </Button>
</Box> 
    </Box>
  </Box>

  {/* Grade de horários */}
  <Box sx={{ marginTop: "30px" }}>
  {/* Cabeçalho da tabela */}
  <Box sx={{
    display: "grid",
    width: "100%", 
    gridTemplateColumns: `80px repeat(${days.length}, 1fr)`, 
    textAlign: "center",
    backgroundColor: "#FFFFFF",
    fontWeight: 600,
    fontSize: "14px",
    marginBottom: "20px",
    color: "#8893A9"
  }}>
    <Typography></Typography>
    {days.map((day, index) => (
      <Typography key={index}>{day.name}</Typography> // Certifique-se de que a API retorna "name" ou ajuste conforme a estrutura retornada
    ))}
  </Box>


    {/* Linha de horários */}
    <Box sx={{
      display: "grid",
      
      gridTemplateColumns: "80px repeat(7, 1fr)",
      gap: "10px",
      alignItems: "center",
    }}>
      {/* Horários na lateral */}
      <Box>
        {["07:30", "08:30", "09:30", "10:30", "11:30", "12:30"].map((time, index) => (
          <Typography key={index} sx={{
            textAlign: "center", fontSize: "14px", backgroundColor: "#FFFFFF", padding: "10px 0" , color:"#212529",             marginBottom: "45px" 
          }}>
            {time}
          </Typography>
        ))}
      </Box>  
      <div
  style={{
    display: "grid",
    gridTemplateColumns: "repeat(6, 183px)",
    marginTop: "-10px",
    gap: "0px",
    borderCollapse: "collapse",
    width: "1100px",
  }}
>
  {combinedData.map((item, index) => (
    <div
      key={item.id || index}
      style={{
        border: "1px solid #D6DEE6",
        padding: "15px",
        height: "40px",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography variant="body1" sx={{ margin: "0", fontSize: "13px" }}>
        {item.discipline} {item.strand} {/* Primeiro Disciplina */}
      </Typography>

      <Typography variant="body2" sx={{ margin: "0", fontSize: "13px" }}>
        {item.room} {/* Outros dados */}
      </Typography>
    </div>
  ))}

  {/* Preencher células vazias para garantir 6 colunas por linha */}
  {combinedData.length % 6 !== 0 &&
    [...Array(6 - (combinedData.length % 6))].map((_, emptyIndex) => (
      <div
        key={`empty-${emptyIndex}`}
        style={{
          border: "1px solid #D6DEE6",
          padding: "15px",
          height: "50px",
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {"\u00A0"}
      </div>
    ))}
</div>


      </Box>
    </Box>
    <div
  style={{
    display: "flex", 
    justifyContent: "flex-end",
    gap: "10px", 
    marginTop: "15px", 
    marginRight: "20px", 
  }}
>
  <Button
    variant="contained"
    sx={{
      backgroundColor: "#FFF",
      color: "#FBC02D", // Define a cor da letra como amarela
      fontWeight: 400,
      height: "40px", // Aumenta um pouco a altura
      width: "120px", // Aumenta a largura do botão
      textTransform: "none",
      borderRadius: "6px",
      ":hover": { backgroundColor: "#FBC02D" },
    }}
    onClick={() => navigate("")}
  >
    Cancelar
  </Button>
  <Button
    variant="contained"
    sx={{
      backgroundColor: "#FBC02D",
      color: "white",
      fontWeight: 400,
      height: "40px", // Aumenta um pouco a altura
      width: "120px", // Aumenta a largura do botão
      textTransform: "none",
      borderRadius: "6px",
      ":hover": { backgroundColor: "#FBC02D" },
    }}
    onClick={handleUpdateSchedule}
    >
    Actualizar
  </Button>
</div>

{successMessage && (
  <Box sx={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',  // Coloca a mensagem em cima de outros elementos
    bottom: '190px',        // Posiciona a mensagem para encostar na parte inferior
    left: '50%',
    transform: 'translateX(-50%)',
    zIndex: 10,
  }}>
    <Alert
      severity="success"
      icon={false}  // Remove o ícone de sucesso
      sx={{
        backgroundColor: "#FFFFFF",  // Cor de fundo branca
        color: "#FBC02D",             // Cor do texto amarelo
        borderRadius: "8px",
        fontWeight: "bold",
        height: "100px",  // Altura ajustada
        textAlign: "left",  // Alinha o texto à esquerda
        lineHeight: "50px",   // Ajusta o espaçamento entre as linhas de texto
        boxShadow: 3,  // Sombra para dar destaque
        padding: "20px",  // Ajustando o padding para ficar mais compacto
        width: "300px", // Largura do retângulo
        marginBottom: "10px",  // Espaço entre a mensagem e o fundo
        paddingTop: "20px",  // Ajustando o espaço acima do texto para movê-lo um pouco para baixo
        paddingLeft: "75px",  // Move o texto um pouco para a direita
      }}
    >
      {successMessage}
      <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              top: '5px',
              right: '5px',
              color: '#FBC02D',
            }}
          >
            <CloseIcon />
          </IconButton>
        </Alert>
      </Box>
)}
      {/* Mensagem de erro */}
      <Snackbar
        open={!!errorMessage}
        autoHideDuration={4000}
        onClose={() => setErrorMessage("")}
      >
        <Alert severity="error">{errorMessage}</Alert>
      </Snackbar>
  </Box>
    </Box>
  );
};

export default SchedulePage;
