import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import axios from "axios";
import { useKeycloak } from "@react-keycloak/web";
import { Grid, Typography, CircularProgress, IconButton } from "@mui/material";
import { i18n } from "../../../translate/i18n";
import PropTypes from 'prop-types';



export default function CourseSelectorModal({ open, onClose, setAddCourseToSchoolYearPayload, setAddCourseToSchoolYear, addCourseToSchoolYearPayload }) {
  const [selectedOption, setSelectedOption] = useState("");
  const [graduations, setGraduations] = useState([]);
  const [employee, setEmployees] = useState([]);
  const [courses, setCourses] = useState([]);
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const { keycloak } = useKeycloak();
  const apiUrl = process.env.REACT_APP_API_URL;
  const [coordinatorYears, setCoordinatorYears] = useState([]);
  const [apiYears, setApiYears] = useState([]);
  const [loadingCourseYearCoordinationId, setLoadingCourseYearCoordinationId] = useState(false);


  const handleCourseCoordinatorSelection = (courseId, value) => {
    setSelectedCourses((sc) => {
      if (sc.find(c => c.courseId === courseId)) {
        sc.find(c => c.courseId === courseId).courseCoordinatorId = value;

        return sc
      } else {
        sc.push({ courseId: courseId, courseCoordinatorId: value, courseYearsIdAndCoordinatorsId: [] });
        return sc;
      }
    })
  };

  const handleYearCoordinatorSelection = async (courseId, year, value) => {
    setCoordinatorYears((prevCoordinatorYears) => {
      const updatedCoordinatorYears = [...prevCoordinatorYears];
      console.log("CORDENADOR: ", updatedCoordinatorYears)
      console.log("VOU ATUALIZAR", updatedCoordinatorYears.find(cy => cy.courseYearId == year))
      if (updatedCoordinatorYears.find(cy => cy.courseYearId == year)) {
        updatedCoordinatorYears.find(cy => cy.courseYearId == year).courseYearId = parseInt(year)
        updatedCoordinatorYears.find(cy => cy.courseYearId == year).courseYearCoordinatorId = value
        getCourseYearCoordinationId(value)
          .then((courseYearCoordinationId) => {
            updatedCoordinatorYears.find(cy => cy.courseYearId == year).courseYearCoordinationId = courseYearCoordinationId
          })
          .catch((error) => console.error(error))
      } else {
        getCourseYearCoordinationId(value)
          .then((courseYearCoordinationId) => {
            updatedCoordinatorYears.push({ courseYearId: parseInt(year), courseYearCoordinatorId: value, courseYearCoordinationId })
          })
          .catch((error) => console.error(error))
      }
      setSelectedCourses((sc) => {
        if (sc.find(c => c.courseId === courseId)) {
          sc.find(c => c.courseId === courseId).courseYearsIdAndCoordinatorsId = updatedCoordinatorYears
          return sc
        }
      })
      return updatedCoordinatorYears;
    });
  };
  const getFullName = (employee) => {
    return `${employee.firstName} ${employee.lastName}`;
  };

  const getCourseYearCoordinationId = async (id) => {
    return new Promise(async (resolve, reject) => {
      setLoadingCourseYearCoordinationId(true)
      axios.get(`${apiUrl}/curricular-Plan/coordinator/${id}`, {
        headers: {
          Authorization: 'Bearer ' + keycloak.token,
        },
      })
        .then((response) => {
          console.log("Full response: ", response);
          if (response?.data) {
            const courseYearCoordinationId = response?.data.courseCoordination?.id;
            console.log("courseYearCoordinator", response?.data)
            resolve(courseYearCoordinationId);
          } else
            reject(`Erro ao buscar dados: No have data`);
        })
        .catch((error) => {
          reject(`Erro ao buscar dados: ${error}`);
        })
        .finally(() => setLoadingCourseYearCoordinationId(false))
    })
  }

  const getList = async (type, set) => {
    try {
      const response = await axios.get(`${apiUrl}/${type}`, {
        headers: {
          Authorization: 'Bearer ' + keycloak.token,
        },
      });

      console.log('Resposta da Requisição:', response);

      if (type === "years") {
        setApiYears(response.data?.data ? response.data.data : response.data);
      } else {
        set(response.data?.data ? response.data.data : response.data);
      }
    } catch (error) {
      console.error('Erro ao buscar dados:', error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getList("graduations", setGraduations);
    getList("employees/coordenators", setEmployees);
    getList("years", setApiYears);
  }, []);
  useEffect(() => {
    if (selectedOption) {
      getCoursesByGraduation(selectedOption);
    }
  }, [selectedOption, apiYears]);

  useEffect(() => {
    const employeeOptionsMap = {};
    employee.forEach((emp) => {
      employeeOptionsMap[emp.id] = getFullName(emp);
    });

  }, [employee]);

  const getCoursesByGraduation = async (graduationName) => {
    try {
      const response = await axios.get(`${apiUrl}/curricular-plan?filter.course.graduation=${encodeURIComponent(graduationName)}`, {
        headers: {
          Authorization: 'Bearer ' + keycloak.token,
        },
      });

      console.log('Resposta da Requisição de Cursos:', response);
      setCourses(response.data?.data ? response.data.data : []);
    } catch (error) {
      console.error('Erro ao buscar cursos:', error);
      setCourses([]);
    } finally {
      setSelectedCourses([])
    }
  };
  const handleConfirm = async (courseId) => {
    setAddCourseToSchoolYearPayload(pl => {
      selectedCourses.forEach(course => {
        var val = pl.find(c => c.courseId === course.courseId)
        if (val) {

        } else {
          pl.push(course)
          console.log("Testando...", selectedCourses, course, val);
        }
      })
      return pl
    })


    setAddCourseToSchoolYear(selectedCourses.map((course) => {
      const ThisCourse = courses.find(c => c.course.id === course.courseId).course
      const ThisCoordinator = employee.find(e => e.id === course.courseCoordinatorId)
      const ThisCourseYearsCoordinators = course?.courseYearsIdAndCoordinatorsId?.map((year) => {
        const ThisYear = apiYears.find(y => y.id === year.courseYearId)
        const ThisYearCoordinator = employee.find(e => e.id === year.courseYearCoordinatorId)
        return { courseYear: ThisYear, courseYearCoordinator: ThisYearCoordinator }
      })
      return ({
        course: [ThisCourse],
        coordinator: ThisCoordinator,
        courseYearsCoordinators: ThisCourseYearsCoordinators
      })

    }))
    onClose(); setSelectedCourses([])
  }
  const handleSelectChange = (key, value) => {
    const [courseId, year] = key.split('_');
    console.log("VALUE: ", year)

    if (year) {
      handleYearCoordinatorSelection(courseId, year, value.id);
    } else {
      handleCourseCoordinatorSelection(courseId, value.id);
    }

  };

  const handleCourseSelection = (course) => {
    console.log(course)
    const courseId = course.course.id;
    const totalNumberOfYears = course.course.totalNumberOfYears;
    setSelectedCourses((sc) => {
      if (sc.find(c => c.courseId === courseId)) sc.splice(sc.indexOf(sc.find(c => c.courseId === courseId)), 1)
      else {
        sc.push({ courseId, courseCoordinationId: course.course.courseCoordination?.id });
      }
      return sc;
    });



    setCourses((prevCourses) =>
      prevCourses.map((course) =>
        course.course.id === courseId
          ? {
            ...course,
            showYears: !course.showYears,
          }
          : course
      )
    );
  };
  return (
    <Modal open={open} onClose={onClose}>
      <Grid
        container
        spacing={3}
        sx={{
          position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", backgroundColor: "white", padding: "20px", width: "30%", maxHeight: "80%", overflowY: "auto", border: "1px solid #000"
        }} >
        <Grid container justifyContent="flex-end">
          <IconButton onClick={() => { onClose(); setSelectedCourses([]) }} sx={{ position: "absolute", top: 0, right: 0, marginRight: 2 }}>
            <img src="/icon/close.svg" alt="Close" style={{ width: 35.96, height: 35.96 }} />
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          <Typography>{i18n.t("others.graduationType")}</Typography>
          <Select
            name="graduation"
            label={i18n.t("others.graduation")}
            value={selectedOption}
            onChange={(e) => setSelectedOption(e.target.value)}
            sx={{ width: "100%", height: "40px", marginBottom: "10px" }}
          >
            {loading ? (
              <MenuItem value="" disabled>
                <CircularProgress size={20} />
              </MenuItem>
            ) : (
              graduations.map((graduation) => (
                <MenuItem key={graduation.id} value={graduation.name}>
                  {graduation.name}
                </MenuItem>
              ))
            )}
          </Select>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body1">{i18n.t("button.addC")}</Typography>
        </Grid>
        {selectedOption && (
          <Grid item xs={12}>
            {courses.length > 0 ? (
              courses.map((course) => (
                <Grid
                  container
                  alignItems="center"
                  key={course.id}
                  sx={{
                    border: '1px solid #fff', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', marginBottom: '5px', cursor: 'pointer', padding: '10px', maxWidth: '100%', marginLeft: "-20px"
                  }}>
                  <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
                    <Checkbox
                      name="courses"
                      checked={selectedCourses?.find((sc) => sc.courseId == course.course.id)}
                      onChange={() => handleCourseSelection(course)}
                      sx={{
                        marginRight: "10px",
                        '&:hover': {
                          backgroundColor: 'transparent',
                        },
                        '& .MuiSvgIcon-root': {
                          fill: selectedCourses?.find((sc) => sc.courseId == course.course.id) ? '#FFC107' : 'initial',
                        },
                      }}
                    />
                    <Typography variant="body2">{course.course.name}</Typography>
                  </Grid>
                  <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginTop: '5px' }}>
                    <Select
                      defaultValue={selectedCourses?.find((sc) => sc.courseId == course.course.id)?.courseCoordinatorId}
                      name="coordinator"
                      onChange={(e) => handleSelectChange(course.course.id, e.target.value)}
                      sx={{ width: "200px", height: "40px", marginLeft: "10px" }}
                      placeholder="Adicionar coordenador"
                    >
                      {loading ? (
                        <MenuItem value="" disabled>
                          <CircularProgress size={20} />
                        </MenuItem>
                      ) : (
                        employee.map((teacher) => (
                          <MenuItem key={teacher.id} value={teacher}>
                            {`${teacher.firstName} ${teacher.lastName}`}
                          </MenuItem>
                        ))
                      )}
                    </Select>
                  </Grid>
                  {selectedCourses.find((sc) => sc.courseId == course.course.id) && course.showYears && (
                    <Grid container>
                      {Array.from({ length: course.course.totalNumberOfYears }, (_, index) => index + 1).map((year) => (
                        <Grid container alignItems="center" key={`${course.course.id}_${year}`}>
                          <Typography name='yearId' variant="body2" sx={{ marginTop: "5px", marginLeft: "50px" }}>
                            {`${year}º ano`}
                          </Typography>
                          <Select
                            defaultValue={selectedCourses?.find((sc) => sc.courseId == course.course.id)?.courseYearsIdAndCoordinatorsId?.find(cyc => cyc.courseYearId == parseInt(year))?.courseYearCoordinatorId}
                            name='emplyeeId'
                            onChange={(e) => handleSelectChange(`${course.course.id}_${year}`, e.target.value)}
                            sx={{ width: "200px", height: "40px", marginLeft: "128px", justifyContent: 'flex-end', marginTop: '5px' }}
                          >
                            <MenuItem value="" disabled>
                              {i18n.t("others.selectEmployee")}
                            </MenuItem>
                            {loading ? (
                              <MenuItem value="" disabled>
                                <CircularProgress size={20} />
                              </MenuItem>
                            ) : (
                              employee.map((teacher) => (
                                <MenuItem key={teacher.id} value={teacher}>
                                  {`${teacher.firstName} ${teacher.lastName}`}
                                </MenuItem>
                              ))
                            )}
                          </Select>
                        </Grid>
                      ))}
                    </Grid>
                  )}
                </Grid>
              ))
            ) : (
              <Typography variant="body2">Nenhum curso disponível para esta graduação.</Typography>
            )}
          </Grid>
        )}
        <Grid
          sx={{ display: "flex", justifyContent: "center", marginTop: "20px", alignItems: "center", marginLeft: "145px", height: "36px" }}
        >
          <Button variant="contained"
            sx={{ backgroundColor: '#FFC107', '&:hover': { backgroundColor: '#FFA000' }, color: '#FFFFFF' }}
            color="primary" onClick={handleConfirm}>{i18n.t("button.add")}</Button>
        </Grid>
      </Grid>
    </Modal>
  );
}
CourseSelectorModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  setAddCourseToSchoolYearPayload: PropTypes.func.isRequired,
  setAddCourseToSchoolYear: PropTypes.func.isRequired,
};

